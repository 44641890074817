import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';
import { NewInstructorVisaStore } from 'stores/skills/instructor-visa-store';
import { isNullOrEmptyOrWhiteSpaces, isNullOrWhiteSpaces } from 'utils/useful-functions';
import LocationEditor from 'widgets/bussiness/location-editor';
import { ItemReference } from 'stores/dataStore';
import { DateTimeService } from 'services/datetime-service';
import { DateInput } from 'widgets/form/dateInput';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import VisaEditor, { VisaItemReference } from 'widgets/bussiness/visa-editor';
import { UserStore } from 'stores/users/users-store';

interface NewInstructorVisaProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newInstructorVisa?: NewInstructorVisaStore;
}

interface NewInstructorVisaViewState {
  instructor: ItemReference;
  ttcLocation: ItemReference;
  travelingLocation: ItemReference;
  visa: VisaItemReference;
  startDate: string;
  expireDate: string;
  userClickedSaved: boolean;
  treatyRequired: boolean;
  noneVisa: boolean;
  isInstructorRestriction: boolean;
  instructorComments: string;
}

@connect(['newInstructorVisa', NewInstructorVisaStore], ['userStore', UserStore])
class NewInstructorVisaView extends React.Component<NewInstructorVisaProps, NewInstructorVisaViewState> {
  private get newInstructorVisaStore() {
    return this.props.newInstructorVisa;
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: NewInstructorVisaProps) {
    super(props);
    this.newInstructorVisaStore.createNew({
      instructorId: '',
      ttcLocationId: '',
      travelingLocationId: '',
      visaId: '',
      visaName: '',
      visaTreatyRequired: false,
      startDate: '',
      expireDate: '',
      isInstructorRestriction: false,
      instructorComments: ''
    });

    this.state = {
      instructor: null,
      ttcLocation: null,
      travelingLocation: null,
      visa: null,
      startDate: null,
      expireDate: null,
      userClickedSaved: false,
      treatyRequired: false,
      noneVisa: false,
      isInstructorRestriction: false,
      instructorComments: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewInstructorVisaProps) {
    if (this.newInstructorVisaStore.state.result && this.newInstructorVisaStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newInstructorVisaStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newInstructorVisaStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const change = {};
    change[property] = value;
    this.newInstructorVisaStore.change({ ...this.newInstructorVisaStore.state.item, ...change });
  }

  private getInstructorName = () => {
    const user = this.identityService.getCurrentUserDto();
    this.newInstructorVisaStore.state.item.instructorId = user.id;
    return `${user.firstName} ${user.lastName}`.trim();
  };

  private getInstructorLocation = () => {
    const user = this.identityService.getCurrentUserDto();
    const instructorLocation = (user.roles || []).find(item => item.role.name === 'Instructor');
    this.newInstructorVisaStore.state.item.ttcLocationId = instructorLocation.location.id;
    return instructorLocation.location.location;
  };

  private handleOnTravelingLocationChange = (travelingLocation: ItemReference) => {
    this.newInstructorVisaStore.state.item.travelingLocationId = travelingLocation ? travelingLocation.id : null;
    this.setState({ userClickedSaved: false });
  };

  private handleOnVisaChange = (visa: VisaItemReference) => {
    let treatyRequired: boolean = false;
    let noneVisa: boolean = false;
    let travelingLocation: ItemReference = null;
    let startDate: string = null;
    let expireDate: string = null;

    if (visa) {
      treatyRequired = visa.visaTreatyRequired;
      noneVisa = visa.title === 'None';
      this.newInstructorVisaStore.state.item.visaId = visa.id;
      this.newInstructorVisaStore.state.item.visaName = visa.title;
      this.newInstructorVisaStore.state.item.visaTreatyRequired = treatyRequired;

      if (treatyRequired) {
        this.newInstructorVisaStore.state.item.travelingLocationId = '';
        travelingLocation = this.state.travelingLocation;
        startDate = this.state.startDate;
        expireDate = this.state.expireDate;
      }

      if (noneVisa) {
        this.newInstructorVisaStore.state.item.startDate = '';
        this.newInstructorVisaStore.state.item.expireDate = '';
      }
    }

    this.setState({
      userClickedSaved: false,
      treatyRequired: treatyRequired,
      noneVisa: noneVisa,
      travelingLocation: travelingLocation,
      startDate: startDate,
      expireDate: expireDate
    });
  };

  public render() {
    const { t } = this.props as any;
    const { treatyRequired, noneVisa } = this.state;
    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newInstructorVisaStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Instructor Visa')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.newInstructorVisaStore.state.result && !this.newInstructorVisaStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newInstructorVisaStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.newInstructorVisaStore.state.item && (
              <Form>
                <Form.Group widths={3}>
                  <Form.Field required>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('Instructor')}</label>
                      <Input disabled value={this.getInstructorName()}></Input>
                    </div>
                  </Form.Field>

                  <Form.Field required error={!this.newInstructorVisaStore.state.item.visaId && this.state.userClickedSaved}>
                    <div
                      className={`required ${this.newInstructorVisaStore.state.item.visaId == null ||
                        isNullOrWhiteSpaces(this.newInstructorVisaStore.state.item.visaId)} field`}
                    >
                      <label>{t('Visa Requirement')}</label>
                      <VisaEditor
                        onChange={this.handleOnVisaChange}
                        value={this.state.visa}
                        placeholder={t('Visa')}
                        loadDataOnOpen={true}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field required>
                    <div className={`field normal-looking-disabled-input`}>
                      <label>{t('TTC Location')}</label>
                      <Input disabled value={this.getInstructorLocation()}></Input>
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={3}>
                  {!treatyRequired && (
                    <Form.Field required error={!this.newInstructorVisaStore.state.item.travelingLocationId && this.state.userClickedSaved}>
                      <div
                        className={`required ${this.newInstructorVisaStore.state.item.travelingLocationId == null ||
                          isNullOrWhiteSpaces(this.newInstructorVisaStore.state.item.travelingLocationId)} field`}
                      >
                        <label>{t('Traveling Location')}</label>
                        <LocationEditor
                          value={this.state.travelingLocation}
                          placeholder={t('Traveling Location')}
                          onChange={this.handleOnTravelingLocationChange}
                        />
                      </div>
                    </Form.Field>
                  )}

                  {!noneVisa && (
                    <Form.Field required error={!this.newInstructorVisaStore.state.item.startDate && this.state.userClickedSaved}>
                      <div
                        className={`required ${this.newInstructorVisaStore.state.item.startDate == null ||
                          isNullOrWhiteSpaces(this.newInstructorVisaStore.state.item.startDate)} field`}
                      >
                        <label>{t('Start Date')}</label>
                        <DateInput
                          iconPosition="right"
                          className="date-picker__left"
                          value={
                            isNullOrEmptyOrWhiteSpaces(this.newInstructorVisaStore.state.item.startDate)
                              ? ''
                              : DateTimeService.toString(this.newInstructorVisaStore.state.item.startDate)
                          }
                          onChange={(_, value) => this.handleValue('startDate', value)}
                          minDate={null}
                        />
                      </div>
                    </Form.Field>
                  )}

                  {!noneVisa && (
                    <Form.Field required error={!this.newInstructorVisaStore.state.item.expireDate && this.state.userClickedSaved}>
                      <div
                        className={`required ${
                          this.newInstructorVisaStore.state.item.expireDate == null ||
                          isNullOrWhiteSpaces(this.newInstructorVisaStore.state.item.expireDate)
                            ? 'field'
                            : ''
                        }`}
                      >
                        <label>{t('Expire Date')}</label>
                        <DateInput
                          iconPosition="right"
                          className="date-picker__right"
                          value={
                            isNullOrEmptyOrWhiteSpaces(this.newInstructorVisaStore.state.item.expireDate)
                              ? ''
                              : DateTimeService.toString(this.newInstructorVisaStore.state.item.expireDate)
                          }
                          onChange={(_, value) => this.handleValue('expireDate', value)}
                          initialValue={
                            isNullOrEmptyOrWhiteSpaces(this.newInstructorVisaStore.state.item.expireDate)
                              ? DateTimeService.toString(DateTimeService.now())
                              : DateTimeService.toString(this.newInstructorVisaStore.state.item.expireDate)
                          }
                          minDate={(() => {
                            const today = DateTimeService.now();
                            const expireDate = this.newInstructorVisaStore.state.item.expireDate
                              ? DateTimeService.toMoment(this.newInstructorVisaStore.state.item.expireDate)
                              : null;

                            if (expireDate && expireDate.isBefore(today, 'day')) {
                              return DateTimeService.toString(today);
                            }
                            return DateTimeService.toString(today);
                          })()}
                        />
                      </div>
                    </Form.Field>
                  )}
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewInstructorVisaView);
