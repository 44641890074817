import React from 'react';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { RequestStatus, ExtendedStatusValidation } from 'stores/requests/requests-store';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { replaceAll } from 'utils/useful-functions';
import { userCanEditGeaOrGmaRequest } from 'utils/extended-requests-utils';

interface RequestCloneButtonProps extends WithTranslation {
  requestStatus: RequestStatus;
  requestCreatorRoles: string[];
  isGea: boolean;
  isGma: boolean;
  extendedStatusValidation?: ExtendedStatusValidation;
  onClone?: () => void;
}

class RequestCloneButton extends Component<RequestCloneButtonProps> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  render() {
    if (!this.isVisible()) return <></>;
    return this.renderCloneButton();
  }

  renderCloneButton() {
    const { t } = this.props;
    return (
      <Button
        className="form__actions__button form__white-btn"
        icon="save"
        labelPosition="right"
        onClick={() => this.props.onClone && this.props.onClone()}
        content={t('Clone')}
      />
    );
  }

  isVisible(): boolean {
    return this.currentUserHasPermission();
  }

  currentUserHasPermission(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const { isGea, isGma, extendedStatusValidation, requestCreatorRoles, requestStatus } = this.props;

    if (IdentityService.isAdmin(userInfo)) return true;
    if (IdentityService.isReporting(userInfo)) return false;

    if ((requestCreatorRoles || []).includes(replaceAll(userInfo.activeRole, ' ', ''))) {
      if (isGma) return userCanEditGeaOrGmaRequest('GMA', extendedStatusValidation, requestCreatorRoles, requestStatus, userInfo);

      if (isGea) return userCanEditGeaOrGmaRequest('GEA', extendedStatusValidation, requestCreatorRoles, requestStatus, userInfo);

      return true;
    }
    return false;
  }
}

export default withTranslation()(RequestCloneButton);
