import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Input } from 'semantic-ui-react';
import {
  InstructorVisaRestrictionDto,
  ChangeInstructorVisaRestrictionStore
} from 'stores/configuration/planner-assistant/instructor-visa-restriction-store';
import { isNullOrEmptyOrWhiteSpaces } from 'utils/useful-functions';
import { ItemReference } from 'stores/dataStore';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { DateInput } from 'widgets/form/dateInput';
import { DateTimeService } from 'services/datetime-service';
import { VisaItemReference } from 'widgets/bussiness/visa-editor';

interface ChangeInstructorVisaRestrictionViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeInstructorVisaRestriction?: ChangeInstructorVisaRestrictionStore;
  currentInstructorVisaRestriction: InstructorVisaRestrictionDto;
}

interface ChangeInstructorVisaRestrictionViewState {
  ttcLocation: ItemReference;
  travelingLocation: ItemReference;
  visa: VisaItemReference;
  startDate: string;
  expireDate: string;
  visaApplicationDuration: number;
  isRestriction: boolean;
  isInstructorRestriction: boolean;
  instructorComments: string;
  comments: string;
  contract: ItemReference;
  flightCost: ItemReference;
  userClickedSaved: boolean;
  treatyRequired: boolean;
  noneVisa: boolean;
}

@connect(['changeInstructorVisaRestriction', ChangeInstructorVisaRestrictionStore])
class ChangeInstructorVisaRestrictionView extends React.Component<
  ChangeInstructorVisaRestrictionViewProps,
  ChangeInstructorVisaRestrictionViewState
> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get changeInstructorVisaRestrictionStore() {
    return this.props.changeInstructorVisaRestriction;
  }

  constructor(props: ChangeInstructorVisaRestrictionViewProps) {
    super(props);

    this.state = {
      ttcLocation: {
        id: this.props.currentInstructorVisaRestriction.instructorVisa.ttcLocationId,
        title: this.props.currentInstructorVisaRestriction.instructorVisa.ttcLocationName
      },
      travelingLocation: {
        id: this.props.currentInstructorVisaRestriction.instructorVisa.travelingLocationId,
        title: this.props.currentInstructorVisaRestriction.instructorVisa.travelingLocationName
      },
      visa: {
        id: this.props.currentInstructorVisaRestriction.instructorVisa.visaId,
        title: this.props.currentInstructorVisaRestriction.instructorVisa.visaName,
        visaTreatyRequired: this.props.currentInstructorVisaRestriction.instructorVisa.visaTreatyRequired
      },
      startDate: this.props.currentInstructorVisaRestriction.instructorVisa.startDate,
      expireDate: this.props.currentInstructorVisaRestriction.instructorVisa.expireDate,
      visaApplicationDuration: this.props.currentInstructorVisaRestriction.restriction.visaApplicationDuration,
      isRestriction: this.props.currentInstructorVisaRestriction.restriction.isRestriction,
      isInstructorRestriction: this.props.currentInstructorVisaRestriction.instructorVisa.isInstructorRestriction,
      comments: this.props.currentInstructorVisaRestriction.restriction.comments,
      instructorComments: this.props.currentInstructorVisaRestriction.instructorVisa.instructorComments,
      contract: {
        id: this.props.currentInstructorVisaRestriction.restriction.contractId,
        title: this.props.currentInstructorVisaRestriction.restriction.contractName
      },
      flightCost: {
        id: this.props.currentInstructorVisaRestriction.restriction.flightCostId,
        title: this.props.currentInstructorVisaRestriction.restriction.flightCostName
      },
      userClickedSaved: false,
      treatyRequired: this.props.currentInstructorVisaRestriction.instructorVisa.visaTreatyRequired,
      noneVisa: this.props.currentInstructorVisaRestriction.instructorVisa.visaName === 'None'
    };

    this.changeInstructorVisaRestrictionStore.state.result = null;

    this.changeInstructorVisaRestrictionStore.change({
      instructorVisa: this.props.currentInstructorVisaRestriction.instructorVisa,
      restriction: this.props.currentInstructorVisaRestriction.restriction
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChangeInstructorVisaRestrictionViewProps) {
    if (this.changeInstructorVisaRestrictionStore.state.result && this.changeInstructorVisaRestrictionStore.state.result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onChangeItem() {
    this.setState({ userClickedSaved: true });
    this.changeInstructorVisaRestrictionStore.update();
  }

  @autobind
  private onCancelChangeItem() {
    this.changeInstructorVisaRestrictionStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });

    if (
      property === 'startDate' ||
      property === 'expireDate' ||
      property === 'isInstructorRestriction' ||
      property === 'instructorComments'
    ) {
      const change = { ...this.changeInstructorVisaRestrictionStore.state.item.instructorVisa };

      if (property === 'startDate') {
        change.startDate = value;
      }
      if (property === 'expireDate') {
        change.expireDate = value;
      }
      if (property === 'isInstructorRestriction') {
        change.isInstructorRestriction = value;
      }
      if (property === 'instructorComments') {
        change.instructorComments = value;
      }

      this.changeInstructorVisaRestrictionStore.change({ instructorVisa: change });
    }

    if (property === 'visaApplicationDuration' || property === 'comments' || property === 'isRestriction' || property === 'flightCostId') {
      const change = { ...this.changeInstructorVisaRestrictionStore.state.item.restriction };

      if (property === 'visaApplicationDuration') {
        change.visaApplicationDuration = value;
      }
      if (property === 'comments') {
        change.comments = value;
      }
      if (property === 'isRestriction') {
        change.isRestriction = value;
      }
      if (property === 'flightCostId') {
        change.flightCostId = value;
        change.flightCostName = '';
      }

      this.changeInstructorVisaRestrictionStore.change({ restriction: change });
    }
  }

  public render() {
    const { t } = this.props as any;
    const { treatyRequired, noneVisa } = this.state;

    return (
      <>
        <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
          <Dimmer active={this.changeInstructorVisaRestrictionStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('')}</Loader>
          </Dimmer>
          <Modal.Header className="modal__header">{t('Edit Instructor Visa Restrictions')}</Modal.Header>
          <Modal.Content>
            <Container>
              {this.changeInstructorVisaRestrictionStore.state.result &&
                !this.changeInstructorVisaRestrictionStore.state.result.isSuccess && (
                  <Message
                    className="error-message__style"
                    icon="exclamation circle"
                    error
                    header={t('An error ocurred')}
                    list={this.changeInstructorVisaRestrictionStore.state.result.messages.map(o => o.body)}
                  />
                )}
              {this.changeInstructorVisaRestrictionStore.state.item && (
                <Form>
                  <Form.Group required widths="3">
                    <Form.Field required>
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Instructor')}</label>
                        <Input disabled value={this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.instructor}></Input>
                      </div>
                    </Form.Field>

                    <Form.Field
                      required
                      error={!this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.visaId && this.state.userClickedSaved}
                    >
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Visa')}</label>
                        <Input disabled value={this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.visaName}></Input>
                      </div>
                    </Form.Field>

                    <Form.Field
                      required
                      error={
                        !this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.ttcLocationId && this.state.userClickedSaved
                      }
                    >
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('TTC Location')}</label>
                        <Input disabled value={this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.ttcLocationName}></Input>
                      </div>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group required widths="3">
                    <Form.Field
                      required
                      error={
                        treatyRequired
                          ? !this.changeInstructorVisaRestrictionStore.state.item.restriction.travelingLocationName
                          : !this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.travelingLocationName &&
                            this.state.userClickedSaved
                      }
                    >
                      <div className={`field normal-looking-disabled-input`}>
                        <label>{t('Traveling Location')}</label>
                        <Input
                          disabled
                          value={
                            treatyRequired
                              ? this.changeInstructorVisaRestrictionStore.state.item.restriction.travelingLocationName
                              : this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.travelingLocationName
                          }
                        ></Input>
                      </div>
                    </Form.Field>

                    {!noneVisa && (
                      <Form.Field>
                        <div className={`field`}>
                          <label>{t('Start Date')}</label>
                          <DateInput
                            iconPosition="right"
                            className="date-picker__left"
                            value={
                              isNullOrEmptyOrWhiteSpaces(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.startDate)
                                ? ''
                                : DateTimeService.toString(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.startDate)
                            }
                            onChange={(_, value) => this.handleValue('startDate', value)}
                            minDate={null}
                          />
                        </div>
                      </Form.Field>
                    )}

                    {!noneVisa && (
                      <Form.Field>
                        <div className={`field`}>
                          <label>{t('Expire Date')}</label>
                          <DateInput
                            iconPosition="right"
                            className="date-picker__right"
                            value={
                              isNullOrEmptyOrWhiteSpaces(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate)
                                ? ''
                                : DateTimeService.toString(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate)
                            }
                            onChange={(_, value) => this.handleValue('expireDate', value)}
                            initialValue={
                              isNullOrEmptyOrWhiteSpaces(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate)
                                ? DateTimeService.toString(DateTimeService.now())
                                : DateTimeService.toString(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate)
                            }
                            minDate={(() => {
                              const expireDate = this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate
                                ? DateTimeService.toMoment(this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.expireDate)
                                : null;
                              const today = DateTimeService.now();

                              if (expireDate && expireDate.isBefore(today, 'day')) {
                                return DateTimeService.toString(expireDate);
                              }
                              return DateTimeService.toString(today);
                            })()}
                          />
                        </div>
                      </Form.Field>
                    )}
                  </Form.Group>

                  <Form.Group required widths="3">
                    <Form.Field>
                      <div className={`field`}>
                        <Form.Input
                          maxlength="1500"
                          fluid={!this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.instructorComments}
                          label={t('Comments')}
                          placeholder={t('Comments')}
                          value={this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.instructorComments}
                          onChange={(e, { value }) => this.handleValue('instructorComments', value)}
                        />
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`field`}>
                        <Form.Checkbox
                          style={{ top: 25 }}
                          toggle
                          checked={this.changeInstructorVisaRestrictionStore.state.item.instructorVisa.isInstructorRestriction}
                          label={t('Restriction')}
                          placeholder={t('Restriction')}
                          onChange={(e, { checked }) => {
                            this.handleValue('isInstructorRestriction', checked);
                          }}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                </Form>
              )}
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button className="basic" onClick={this.onCancelChangeItem}>
              {t('Cancel')}
            </Button>
            <Button onClick={() => this.onChangeItem()} positive>
              {t('Save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeInstructorVisaRestrictionView);
