import {
  RequestStatus,
  Categories,
  ChangeRequestValidator,
  ChangeRequestDto,
  RequestDto,
  SupportDetails,
  SupportPositions,
  NewSupportPositions,
  ExtendedStatusValidation,
  RequestMachinesDto
} from './requests-store';
import { FormStore } from '../formStore';
import { repository } from 'redux-scaffolding-ts';
import { ValidationResult, ValidationFailure } from 'fluent-ts-validator';
import { ItemReference } from 'stores/dataStore';
import { LocationItemReference } from 'widgets/bussiness/location-editor';
import { MachineListItemViewModel } from 'site/pages/shared/events-and-requests/machine-list-item';
import { StudentDto } from 'stores/students/student-model';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { CommandResult } from 'stores/types';
import i18n from 'i18n';
import { EventFieldGroups, EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { DateTimeService } from 'services/datetime-service';
import { isGMARequest, isGEARequest } from 'utils/request-utils';

export interface RequestFormViewModel {
  id: string;
  friendlyId: string;
  title: string;
  status: RequestStatus;
  statusDescription: string;

  hasStudents: boolean;
  studentsMandatory: boolean;
  hasInstructors: boolean;
  hasRequestDetails: boolean;
  hasEventDetails: boolean;
  hasSupportDetails: boolean;
  rolesThatCreateRequests: string[];
  rolesThatValidateRequests: string[];

  eventTypeColor: string;
  eventTypeName: string;
  originalEventTypeId: string;
  priorityLevel: string;
  desiredEventDuration: string;
  requestingLocation: LocationItemReference;
  priority: ItemReference;
  startDate: string;
  endDate: string;

  trainingLevel: ItemReference;
  customization: ItemReference;
  deliveryMethod: ItemReference;
  language: ItemReference;

  instructorLocation: ItemReference;
  instructor: ItemReference;

  eventLocation: ItemReference;
  profession: ItemReference;
  requestReason: ItemReference;
  requestReasonComments: string;
  category: Categories;
  machineRelated: boolean;
  pattern: ItemReference;
  machines: MachineListItemViewModel[];
  nmrTrainingName: ItemReference;
  nmrFunctionalSubArea: ItemReference;
  nmrFunctionalArea: ItemReference;
  nmrCluster: ItemReference;
  requestPreApprovedDuringASPCheck: boolean;

  supportDetails: SupportDetails;

  studentsNumber: number;
  students: StudentDto[];

  comments: string;

  isGEA: boolean;
  isGMA: boolean;
  eventTypeCategory: EventTypeCategory;
  extendedStatusValidation: ExtendedStatusValidation;
}

export function buildRequestFormViewModel(request: RequestDto): RequestFormViewModel {
  return {
    id: request.id,
    status: request.status,
    statusDescription: request.statusDescription,
    title: request.title,
    friendlyId: request.friendlyId,
    desiredEventDuration: request.desiredEventDuration,
    eventTypeColor: request.eventType.eventsColor,
    eventTypeName: request.eventType.name,
    originalEventTypeId: request.eventType.originalEventTypeId,
    priorityLevel: request.eventType.priorityLevel,
    requestingLocation: request.requestingLocationId ? { id: request.requestingLocationId, title: request.requestingLocationName } : null,
    startDate: request.startDate,
    endDate: request.endDate,
    priority: request.priorityId ? { id: request.priorityId, title: request.priorityName } : null,

    hasStudents: request.eventType.participants,
    studentsMandatory: !request.eventType.participantsOptional,
    hasInstructors: request.eventType.instructor === 'Yes',
    hasRequestDetails: (request.eventType.requestFieldGroups || []).some(x => x === 'RequestDetails'),
    hasEventDetails: (request.eventType.requestFieldGroups || []).some(x => x === 'EventDetails'),
    rolesThatCreateRequests: request.eventType.rolesThatCreateRequests,
    rolesThatValidateRequests:
      EventTypeCategory[request.eventType.eventTypeCategory] === EventTypeCategory.Extended
        ? request.eventType.rolesThatValidateRequestsExtended || []
        : [request.eventType.roleThatValidateRequests].filter(x => x != null),
    hasSupportDetails: (request.eventType.requestFieldGroups || []).includes(String(EventFieldGroups[EventFieldGroups.SupportDetails])),

    supportDetails: request.supportDetails,

    pattern: request.patternId ? { id: request.patternId, title: request.patternName } : null,
    machines: (request.requestMachines || []).map(requestedMachine => ({
      cluster: requestedMachine.machineRelatedClusterId
        ? { id: requestedMachine.machineRelatedClusterId, title: requestedMachine.machineRelatedClusterName }
        : null,
      equipmentType: requestedMachine.equipmentTypeId
        ? { id: requestedMachine.equipmentTypeId, title: requestedMachine.equipmentTypeName }
        : null,
      oem: requestedMachine.oemId ? { id: requestedMachine.oemId, title: requestedMachine.oemName } : null,
      machineModel: requestedMachine.machineModelId
        ? { id: requestedMachine.machineModelId, title: requestedMachine.machineModelName }
        : null,
      machineUnits: requestedMachine.machineUnitRequestMachines,
      plcTypes: requestedMachine.plcTypeRequestMachines
    })),

    nmrCluster: request.nmrClusterId ? { id: request.nmrClusterId, title: request.nmrClusterName } : null,
    nmrFunctionalArea: request.nmrFunctionalAreaId ? { id: request.nmrFunctionalAreaId, title: request.nmrFunctionalAreaName } : null,
    nmrFunctionalSubArea: request.nmrFunctionalSubAreaId
      ? { id: request.nmrFunctionalSubAreaId, title: request.nmrFunctionalSubAreaName }
      : null,
    nmrTrainingName: request.nmrTrainingNameId ? { id: request.nmrTrainingNameId, title: request.nmrTrainingNameName } : null,
    instructor: request.instructorId ? { id: request.instructorId, title: request.instructorName } : null,
    instructorLocation: request.instructorLocationId ? { id: request.instructorLocationId, title: request.instructorLocationName } : null,

    eventLocation: request.eventLocationId ? { id: request.eventLocationId, title: request.eventLocationName } : null,
    profession: request.roleId ? { id: request.roleId, title: request.roleName } : null,
    requestReason: request.requestReasonId ? { id: request.requestReasonId, title: request.requestReasonName } : null,
    requestReasonComments: request.requestReasonComments,
    requestPreApprovedDuringASPCheck: null, //request.requestPreApprovedDuringASPCheck,
    category: request.category,
    machineRelated: request.isMachineRelated,

    students: request.students || [],
    studentsNumber: request.studentsNumber,

    customization: request.customizationDataId ? { id: request.customizationDataId, title: request.customizationDataName } : null,
    deliveryMethod: request.deliveryMethodId ? { id: request.deliveryMethodId, title: request.deliveryMethodName } : null,
    language: request.languageId ? { id: request.languageId, title: request.languageName } : null,
    trainingLevel: request.trainingLevelId ? { id: request.trainingLevelId, title: request.trainingLevelName } : null,

    comments: request.comments,
    isGEA: isGEARequest(request),
    isGMA: isGMARequest(request),
    eventTypeCategory: EventTypeCategory[request.eventType.eventTypeCategory],
    extendedStatusValidation: request.extendedStatusValidation
  };
}

class RequestFormViewModelValidator extends ExtendedAbstractValidator<RequestFormViewModel> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ supportDetails: { totalCostLocationA, totalCostLocationB, totalCostLocationC, totalCostLocationD } }) =>
          totalCostLocationA > 0 || totalCostLocationB > 0 || totalCostLocationC > 0 || totalCostLocationD > 0
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .when(x => !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t('At least one support position need total costs'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ supportDetails: { newSupportPositions } }) =>
          (newSupportPositions || []).length > 0 && newSupportPositions.every(y => y.requestedHC < 31)
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .withFailureMessage(i18n.t('Support Positions maximum is 30'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ supportDetails, startDate, endDate }) =>
        supportDetails.supportPositions.all((item, i) =>
          new SupportPositionValidator(startDate, endDate, i, this.addErrors).extendValidate(item).isValid()
        )
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .when(x => !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x.supportDetails)
      .fulfills(({ supportPositions }) => (supportPositions || []).length > 0)
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .when(x => !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t('At least one Support Position is required'));

    this.validateIf(x => x.supportDetails)
      .fulfills(({ newSupportPositions }) => (newSupportPositions || []).length > 0)
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .when(x => x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t('At least one Support Position is required'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ supportDetails, startDate, endDate }) =>
        supportDetails.newSupportPositions.all((item, i) =>
          new NewSupportPositionsValidator(startDate, endDate, i, this.addErrors).extendValidate(item).isValid()
        )
      )
      .when(({ hasSupportDetails }) => hasSupportDetails)
      .when(x => x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t(''));

    this.validateIf(t => t)
      .fulfills(x => x.studentsNumber > 0)
      .when(({ hasStudents, studentsMandatory }) => hasStudents && studentsMandatory)
      .withFailureMessage(i18n.t('Students Assigned must be greater than 0'));

    this.validateIf(({ hasRequestDetails, requestReason }) => hasRequestDetails && requestReason.id)
      .isNotEmpty()
      .withFailureMessage(i18n.t(`Request Reason is required`));

    this.validateIf(({ requestReasonComments }) => requestReasonComments.trim())
      .isNotEmpty()
      .when(x => x.eventTypeCategory === EventTypeCategory.Extended)
      .withFailureMessage(i18n.t('Support Need Explanation is required'));

    this.validateIf(x => x)
      .fulfills(
        ({ machines }) =>
          (machines || []).length > 0 &&
          machines.every((machine, i) => new MachineryValidator(i, this.addErrors).extendValidate(machine).isValid())
      )
      .when(x => x.pattern && x.machineRelated && x.hasRequestDetails && !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t(`At least one machine row is invalid`));

    this.validateIf(x => x)
      .fulfills(
        ({ machines }) =>
          (machines || []).length > 0 &&
          machines.every((machine, i) => new NoPatternMachineryValidator(i, this.addErrors).extendValidate(machine).isValid())
      )
      .when(x => !x.pattern && x.machineRelated && x.hasRequestDetails && !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t(`At least one machine row is invalid`));

    this.validateIfString(x => x.nmrCluster?.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.hasRequestDetails && !x.machineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.nmrFunctionalArea?.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.hasRequestDetails && !x.machineRelated)
      .withFailureMessage(i18n.t('Functional Area is required'));
  }
}

export class MachineryValidator extends ExtendedAbstractValidator<MachineListItemViewModel> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const prefix = i18n.t(`MachineRow as position ${idx + 1}`);

    this.validateIfString(x => x.cluster?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentType?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('Equipment Type is required')}`);
  }
}

export class NoPatternMachineryValidator extends ExtendedAbstractValidator<MachineListItemViewModel> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const prefix = i18n.t(`MachineRow as position ${idx + 1}`);

    this.validateIfString(x => x.cluster?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentType?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('Equipment Type is required')}`);

    this.validateIfString(x => x.oem?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('OEM is required')}`);

    this.validateIfString(x => x.machineModel?.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(`${prefix}: ${i18n.t('Machine Model is required')}`);
  }
}

export class NewSupportPositionMachinesValidator extends ExtendedAbstractValidator<RequestMachinesDto> {
  constructor(idxGeneral: number, idxMachine: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Machine at position ${idxGeneral + 1} - ${idxMachine + 1}`);

    this.validateIfString(x => x.machineRelatedClusterId)
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Cluster')}`);

    this.validateIfString(x => x.equipmentTypeId)
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Equipment Type')}`);

    this.validateIfString(x => x.oemId)
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine OEM')}`);

    this.validateIfString(x => x.machineModelId)
      .isNotNull()
      .isNotEmpty()
      .isUuid('4')
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid machine Model')}`);
  }
}

export class SupportPositionValidator extends ExtendedAbstractValidator<SupportPositions> {
  constructor(eventStart: string, eventEnd: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const prefix = i18n.t(`Support Item at position ${idx + 1}`);

    this.validateIf(x => x.duration)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Duration')}`);

    this.validateIf(x => x.positionCode)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Position Code')}`);

    this.validateIfNumber(x => x.requestedHC)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Requested HC')}`);

    this.validateIf(x => x.startDate)
      .isNotNull()
      .isDefined()
      .fulfills(start => DateTimeService.toMoment(eventStart).isSameOrBefore(DateTimeService.toMoment(start), 'day'))
      .withFailureMessage(`${prefix}: ${i18n.t('Period Date From is not in the event range')}`);

    this.validateIf(x => x.endDate)
      .isNotNull()
      .isDefined()
      .fulfills(end => DateTimeService.toMoment(eventEnd).isSameOrAfter(DateTimeService.toMoment(end), 'day'))
      .withFailureMessage(`${prefix}: ${i18n.t('Period Date To is not in the event range')}`);

    this.validateIf(x => x.locationA)
      .fulfills(location => !location || !location.locationId || (location.estimatedCost && location.estimatedCost > 0))
      .withFailureMessage(`${prefix}: ${i18n.t('Supplying Location A is missing estimated costs')}`);

    this.validateIf(x => x.locationB)
      .fulfills(location => !location || !location.locationId || (location.estimatedCost && location.estimatedCost > 0))
      .withFailureMessage(`${prefix}: ${i18n.t('Supplying Location B is missing estimated costs')}`);

    this.validateIf(x => x.locationC)
      .fulfills(location => !location || !location.locationId || (location.estimatedCost && location.estimatedCost > 0))
      .withFailureMessage(`${prefix}: ${i18n.t('Supplying Location C is missing estimated costs')}`);

    this.validateIf(x => x.locationC)
      .fulfills(location => !location || !location.locationId || (location.estimatedCost && location.estimatedCost > 0))
      .withFailureMessage(`${prefix}: ${i18n.t('Supplying Location D is missing estimated costs')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(
        ({ locationA, locationB, locationC, locationD }) =>
          !!locationA?.locationId || !!locationB?.locationId || !!locationC?.locationId || !!locationD?.locationId
      )
      .withFailureMessage(`${prefix}: ${i18n.t('There are empty locations')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ locationA, locationB, locationC, locationD }) => {
        const locationIds = [];
        if (locationA?.locationId) locationIds.push(locationA.locationId);
        if (locationB?.locationId) locationIds.push(locationB.locationId);
        if (locationC?.locationId) locationIds.push(locationC.locationId);
        if (locationD?.locationId) locationIds.push(locationD.locationId);
        const duplicatedLoc = (locationIds || []).find((item, index) => locationIds.indexOf(item) !== index);
        return duplicatedLoc == null;
      })
      .withFailureMessage(`${prefix}: ${i18n.t('There are repeated locations')}`);
  }
}

export class NewSupportPositionsValidator extends ExtendedAbstractValidator<NewSupportPositions> {
  constructor(eventStart: string, eventEnd: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const prefix = i18n.t(`Support Item at position ${idx + 1}`);

    this.validateIf(x => x.supportPositionRoleId)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Role')}`);

    this.validateIfNumber(x => x.requestedHC)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Requested HC')}`);

    this.validateIfNumber(x => x.requestedManDays)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Requested Man Days')}`);

    this.validateIfNumber(x => x.requestedWorkingManHours)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Requested Working Man Hours')}`);

    this.validateIfNumber(x => x.theoreticalCost)
      .isNotNull()
      .isDefined()
      .isGreaterThan(0)
      .withFailureMessage(`${prefix}: ${i18n.t('There is no valid Theoretical Cost')}`);

    this.validateIf(x => x.startDate)
      .isNotNull()
      .isDefined()
      .fulfills(start => DateTimeService.toMoment(eventStart).isSameOrBefore(DateTimeService.toMoment(start), 'day'))
      .withFailureMessage(`${prefix}: ${i18n.t('Period Date From is not in the event range')}`);

    this.validateIf(x => x.endDate)
      .isNotNull()
      .isDefined()
      .fulfills(end => DateTimeService.toMoment(eventEnd).isSameOrAfter(DateTimeService.toMoment(end), 'day'))
      .withFailureMessage(`${prefix}: ${i18n.t('Period Date To is not in the event range')}`);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(
        ({ machineModels }) =>
          (machineModels || []).length > 0 &&
          (machineModels || []).every((m, machinesIdx) => {
            return new NewSupportPositionMachinesValidator(idx, machinesIdx, this.addErrors).extendValidate(m).isValid();
          })
      )
      .withFailureMessage(`${prefix}: ${i18n.t('At least one support position row is invalid.')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ machineModels }) => {
        const machineIds = [];

        machineModels.map(x => {
          machineIds.push(x.machineModelId);
          return null;
        });

        const duplicatedMachines = (machineIds || []).find((item, index) => machineIds.indexOf(item) !== index);
        return duplicatedMachines == null;
      })
      .withFailureMessage(`${prefix}: ${i18n.t('There are repeated machines.')}`);
  }
}

@repository('@@REQUESTS', 'requests.request-form')
export class RequestFormStore extends FormStore<RequestFormViewModel> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = '';
  retrievePath = '';
  updatePath = 'update-request';
  clonePath = 'clone-request';

  constructor() {
    super('NEW_REQUESTFORM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: RequestFormViewModel): ValidationResult {
    var changeRequestDto = this.toChangeRequestDto();
    return new ChangeRequestValidator().validate(changeRequestDto);
  }

  public async updateRequest() {
    const changeRequestDto = this.toChangeRequestDto();
    const changeRequestDtoValidation = new ChangeRequestValidator().validate(changeRequestDto);
    const requestFormViewModelValidation = new RequestFormViewModelValidator().extendValidate(this.state.item);

    if (changeRequestDtoValidation.isInvalid() || requestFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...changeRequestDtoValidation.getFailures(), ...requestFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return;
    }

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.put<ChangeRequestDto, CommandResult<ChangeRequestDto>>(`${this.baseUrl}/${this.updatePath}`, changeRequestDto)
    );
    return result.data;
  }

  public async cloneRequest() {
    const cloneRequestDto = this.toChangeRequestDto();
    const cloneRequestDtoValidation = new ChangeRequestValidator().validate(cloneRequestDto);
    const requestFormViewModelValidation = new RequestFormViewModelValidator().extendValidate(this.state.item);

    if (cloneRequestDtoValidation.isInvalid() || requestFormViewModelValidation.isInvalid()) {
      const validationResult = new ValidationResult();
      validationResult.addFailures([...cloneRequestDtoValidation.getFailures(), ...requestFormViewModelValidation.getFailures()]);
      this.dispatch(this.ENTITY_VALIDATED, validationResult);
      return;
    }

    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<ChangeRequestDto, CommandResult<ChangeRequestDto>>(`${this.baseUrl}/${this.clonePath}`, cloneRequestDto)
    );
    return result.data;
  }

  toChangeRequestDto(): ChangeRequestDto {
    const { item } = this.state;
    const { supportDetails } = item;
    return {
      id: item.id,
      category: item.category,
      comments: item.comments,
      customizationDataId: item.customization ? item.customization.id : null,
      deliveryMethodId: item.deliveryMethod ? item.deliveryMethod.id : null,
      desiredEventDuration: item.desiredEventDuration,
      endDate: item.endDate,
      eventLocationId: item.eventLocation ? item.eventLocation.id : null,
      eventTypeCategory: item.eventTypeCategory,
      instructorId: item.instructor ? item.instructor.id : null,
      instructorLocationId: item.instructorLocation ? item.instructorLocation.id : null,
      isMachineRelated: item.machineRelated,
      languageId: item.language ? item.language.id : null,
      nmrClusterId: item.nmrCluster ? item.nmrCluster.id : null,
      nmrFunctionalAreaId: item.nmrFunctionalArea ? item.nmrFunctionalArea.id : null,
      nmrFunctionalSubAreaId: item.nmrFunctionalSubArea ? item.nmrFunctionalSubArea.id : null,
      nmrTrainingNameId: item.nmrTrainingName ? item.nmrTrainingName.id : null,
      patternId: item.pattern ? item.pattern.id : null,
      priorityId: item.priority ? item.priority.id : null,
      requestMachines: (item.machines || []).map(x => ({
        equipmentTypeId: x.equipmentType ? x.equipmentType.id : null,
        equipmentTypeName: x.equipmentType ? x.equipmentType.title : null,
        machineModelId: x.machineModel ? x.machineModel.id : null,
        machineModelName: x.machineModel ? x.machineModel.title : null,
        machineRelatedClusterId: x.cluster ? x.cluster.id : null,
        machineRelatedClusterName: x.cluster ? x.cluster.title : null,
        machineUnitRequestMachines: x.machineUnits,
        oemId: x.oem ? x.oem.id : null,
        oemName: x.oem ? x.oem.title : null,
        plcTypeRequestMachines: x.plcTypes
      })),
      requestOwnerId: null,
      requestReasonId: item.requestReason ? item.requestReason.id : null,
      requestReasonComments: item.requestReasonComments,
      //requestPreApprovedDuringASPCheck: item.requestPreApprovedDuringASPCheck,
      requestingLocationId: item.requestingLocation ? item.requestingLocation.id : null,
      roleId: item.profession ? item.profession.id : null,
      startDate: item.startDate,
      students: (item.students || []).map(x => x.id),
      studentsNumber: item.studentsNumber,
      title: item.title,
      trainingLevelId: item.trainingLevel ? item.trainingLevel.id : null,
      supportDetails: supportDetails
        ? {
            totalRequestedWorkingManHours: supportDetails.totalRequestedWorkingManHours,
            totalRequestedManDays: supportDetails.totalRequestedManDays,
            totalRequestedManMonths: supportDetails.totalRequestedManMonths,
            totalTheoreticalCost: supportDetails.totalTheoreticalCost,
            totalCostLocationA: supportDetails.totalCostLocationA,
            totalCostLocationB: supportDetails.totalCostLocationB,
            totalCostLocationC: supportDetails.totalCostLocationC,
            totalCostLocationD: supportDetails.totalCostLocationD,
            supportPositions: (supportDetails.supportPositions || []).map(
              ({ positionCode, comment, requestedHC, duration, locationA, locationB, locationC, locationD, startDate, endDate }) => ({
                comment,
                duration,
                endDate,
                locationA:
                  locationA && locationA.locationId ? { locationId: locationA.locationId, estimatedCost: locationA.estimatedCost } : null,
                locationB:
                  locationB && locationB.locationId ? { locationId: locationB.locationId, estimatedCost: locationB.estimatedCost } : null,
                locationC:
                  locationC && locationC.locationId ? { locationId: locationC.locationId, estimatedCost: locationC.estimatedCost } : null,
                locationD:
                  locationD && locationD.locationId ? { locationId: locationD.locationId, estimatedCost: locationD.estimatedCost } : null,
                positionCode,
                requestedHC,
                startDate
              })
            ),
            newSupportPositions: (supportDetails.newSupportPositions || []).map(
              ({
                requestedHC,
                startDate,
                endDate,
                patternId,
                patternName,
                machineModels,
                requestedManDays,
                requestedWorkingManHours,
                theoreticalCost,
                supportPositionRoleId
              }) => ({
                endDate,
                patternId,
                patternName,
                machineModels,
                requestedHC,
                startDate,
                requestedManDays,
                requestedWorkingManHours,
                theoreticalCost,
                supportPositionRoleId
              })
            ),
            requestPreApprovedDuringASP: supportDetails.requestPreApprovedDuringASP,
            isNewSupportPositionModel: supportDetails.isNewSupportPositionModel
          }
        : null
    };
  }
}
